import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Pin: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" className={clss}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h17v17H0z" />
                <path
                    d="M8.5 2.507c-2.642 0-4.8 2.367-4.8 5.264 0 1.877.8 3.815 2.344 5.631 1.136 1.347 2.27 2.143 2.326 2.183l.13.122.149-.102c.056-.04 1.172-.877 2.307-2.244C12.48 11.525 13.3 9.607 13.3 7.79c0-2.918-2.158-5.284-4.8-5.284zm0 12.445a14.005 14.005 0 0 1-2.065-1.978c-.987-1.184-2.177-3.04-2.177-5.203 0-2.571 1.898-4.652 4.242-4.652 2.344 0 4.242 2.081 4.242 4.652 0 3.59-3.479 6.57-4.242 7.181zm0-9.487c-1.06 0-1.916.94-1.916 2.102 0 1.163.856 2.121 1.916 2.121s1.916-.938 1.916-2.101S9.56 5.465 8.5 5.465zm0 3.611c-.744 0-1.358-.673-1.358-1.49 0-.815.614-1.489 1.358-1.489.744 0 1.358.674 1.358 1.49s-.614 1.49-1.358 1.49z"
                    stroke={color}
                    strokeWidth=".672"
                />
            </g>
        </svg>
    );
};

export default Pin;
